import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, finalize, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { User } from '../models/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private destroy$ = new Subject<void>();
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  currentUser$: Observable<User | null>;
  currentUserSubject: BehaviorSubject<User | null>;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private router: Router) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.currentUserSubject = new BehaviorSubject<User | null>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.getUserByToken().pipe(takeUntil(this.destroy$)).subscribe();
  }

  get currentUserValue(): User {
    return this.currentUserSubject.value!;
  }

  set currentUserValue(profile: User) {
    this.currentUserSubject.next(profile);
  }

  getUserByToken(): Observable<User | undefined> {
    const user = this.getAuthFromLocalStorage();
    if (!user) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return of(user).pipe(
      map((user: User) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );

    // this.isLoadingSubject.next(true);
    // return of({
    //   schoolId: this.authService.currentUserValue?.schoolId,
    //   firstName: 'สุรศักดิ์',
    //   lastName: 'หาญลำยวง',
    //   email: 'surasak.hanl@gmail.com'
    // } as User)
    //   .pipe(
    //     map((profile: User) => {
    //       if (profile) {
    //         this.currentUserSubject = new BehaviorSubject<User | null>(profile);
    //       } else {
    //         this.logout();
    //       }
    //       return profile;
    //     }),
    //     finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(user: User): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (user) {
      localStorage.setItem('dsap-user', JSON.stringify(user));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): User | undefined {
    try {
      const lsValue = localStorage.getItem('dsap-user');
      if (!lsValue) {
        return undefined;
      }

      const userData = JSON.parse(lsValue);
      return userData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  signIn(user: User) {
    this.setAuthFromLocalStorage(user);
    window.location.reload();
  }

  logout() {
    localStorage.clear();
    console.log("Clear Local Storage");
    window.location.replace('/onboarding');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}