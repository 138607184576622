import { Component } from '@angular/core';

@Component({
  selector: 'app-basic-setting',
  templateUrl: './basic-setting.component.html',
  styleUrl: './basic-setting.component.scss'
})
export class BasicSettingComponent {

}
