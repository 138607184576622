import { Component } from '@angular/core';

@Component({
  selector: 'app-land',
  templateUrl: './land.component.html',
  styleUrl: './land.component.scss'
})
export class LandComponent {

}
