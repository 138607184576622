import { Component } from '@angular/core';

@Component({
  selector: 'app-poc',
  templateUrl: './poc.component.html',
  styleUrl: './poc.component.scss'
})
export class PocComponent {

}
